import React from "react";
import vistar_logo from "../assets/connectors_logos/vistar.png";
import googleAds_logo from "../assets/connectors_logos/googleads.png";
import dv360_logo from "../assets/connectors_logos/dv360.png";
import fb_logo from "../assets/connectors_logos/fb.png";
import ttd_logo from "../assets/connectors_logos/ttd.png";
import google_sheets_logo from "../assets/connectors_logos/googlesheets.png";
import double_verify_logo from "../assets/connectors_logos/doubleverify.png";
import { GoClock } from "react-icons/go";
import { Link } from "react-router-dom";

interface ConnectorProps {
  connector_icon: string;
  connector_text: string;
  connector_btn_label: string;
  connector_setUpTime_label: string;
  connector_setupTime_time: string;
}

interface ConnectorItemProps {
  connector: ConnectorProps;
}

const ConnectorsItemComp: React.FC<ConnectorItemProps> = ({ connector }) => {
  const getConnectorLogo = () => {
    switch (connector.connector_text.toLowerCase()) {
      case "vistar":
        return vistar_logo;
      case "dv 360":
        return dv360_logo;
      case "the trade desk":
        return ttd_logo;
      case "facebook":
        return fb_logo;
      case "google ads":
        return googleAds_logo;
      case "google sheets":
        return google_sheets_logo;
      case "double verify":
        return double_verify_logo;
      default:
        break;
    }
  };
  return (
    <div className="border w-[184px]">
      <div className="flex flex-col justify-center items-center mt-6 mb-4">
        <img
          src={getConnectorLogo()}
          alt="connector_logo"
          className="h-12 w-12"
        />
        <p className="my-4 text-sm font-semibold">{connector.connector_text}</p>
        <Link
          to="/connectors"
          state={{ connectorText: connector.connector_text }}
          className="border-[0.75px] border-[#286043] text-xs font-semibold py-[6px] px-3"
        >
          {connector.connector_btn_label}
        </Link>
      </div>
      <div className="border-t">
        <p className="flex justify-center items-center my-3 gap-1 text-xs font-bold text-gray-500">
          <GoClock />
          {connector.connector_setUpTime_label} :{" "}
          {connector.connector_setupTime_time}
        </p>
      </div>
    </div>
  );
};

export default ConnectorsItemComp;
