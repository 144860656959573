import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import opportunities_logo from "../assets/opportunities_logo.png";
import filter_lines from "../assets/filter_lines.png";
import ActionSection from "../components/actionSection";
import brandSafety from "../json/brandSafety.json";
import dataSwitch from "../json/switch.json";
import NoActionsFound from "../components/noActionsFound";
import { RiDeleteBinLine } from "react-icons/ri";
import DialogComponent from "../components/dialogComponent";
import { toast } from "react-hot-toast";
import CustomToast from "../components/CustomToast";
import { IconType } from "react-icons";
import { FaCircleCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { MdRestore } from "react-icons/md";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import Skeleton from "../components/Skeleton";
import { ChecksResponse, Checks } from "../interfaces/brandSafety";
import UserAvatar from "../components/userAvatar/UserAvatar";
import Loader from "../components/statusIndicator/loader";

const FilterPopup = lazy(() => import("../components/FilterPopup"));

const initialUrl = "/checks/all";

const Opportunities: React.FC = () => {
  const [allChecksData, setAllChecksData] = useState<ChecksResponse | null>(
    null
  );
  const [brandSafetyData, setBrandSafetyData] = useState<Checks[]>([]);
  const [nextBtnLink, setNextBtnLink] = useState<string | null>(null);
  const [previousBtnLink, setPreviousBtnLink] = useState<string | null>(null);

  const [queuedActionsData, setQueuedActionsData] = useState<Checks[]>([]);
  const [archiveActions, setArchiveActions] = useState<Checks[]>([]);
  const [selectedForDelete, setSelectedForDelete] = useState<any | null>(null);

  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showPermanentlyDeletePopup, setShowPermanentlyDeletePopup] =
    useState<boolean>(false);

  const [showRestorePopup, setShowRestorePopup] = useState<boolean>(false);
  const [showMarkAsIrrelevantPopup, setShowMarkAsIrrelevantPopup] =
    useState<boolean>(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState<boolean>(false);
  const [userFeedback, setUserFeedback] = useState<string>("");
  const [showFilterPopup, setShowFilterPopup] = useState<boolean>(false);

  //selected from queue
  const [selectedFromQueue, setSelectedFromQueue] = useState<Checks[]>([]);

  //restore
  const [selectedForRestore, setSelectedForRestore] = useState<any | null>(
    null
  );

  const [urlLink, setUrlLink] = useState<string>(initialUrl);
  const [pageCount, setPageCount] = useState<string>("1");
  const [loadingBrandSafety, setLoadingBrandSafety] = useState<boolean>(false);
  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    }
  }, [accessToken, refreshToken, navigate]);

  useEffect(() => {
    if (allChecksData) {
      setBrandSafetyData(allChecksData.results);
      setNextBtnLink(allChecksData.next);
      setPreviousBtnLink(allChecksData.previous);
    }
  }, [allChecksData]);

  const handleLoadMoreBtn = (btnText: string) => {
    if (btnText.toLocaleLowerCase() === "next" && nextBtnLink) {
      const result = nextBtnLink.substring(
        nextBtnLink.indexOf("/checks"),
        nextBtnLink.length
      );
      setUrlLink(result);
      const pageNumber = new URL(nextBtnLink).searchParams.get("page");
      if (pageNumber) {
        setPageCount(pageNumber);
      }
    } else if (btnText.toLocaleLowerCase() === "previous" && previousBtnLink) {
      const result = previousBtnLink.substring(
        previousBtnLink.indexOf("/checks"),
        previousBtnLink.length
      );
      setUrlLink(result);
      const pageNumber = new URL(previousBtnLink).searchParams.get("page");
      setPageCount(pageNumber ?? "1");
    } else if (btnText.toLocaleLowerCase() === "first_page") {
      setUrlLink(initialUrl);
      setPageCount("1");
    }
  };

  useEffect(() => {
    let isMounted = true;

    const getAllOpportunitiesData = async () => {
      if (!accessToken || !refreshToken) {
        refreshAccessToken();
        return;
      }

      setLoadingBrandSafety(true);

      if (dataSwitch.use_demo_data_for_opportunities) {
        setAllChecksData(brandSafety.data);
        setLoadingBrandSafety(false);
      } else {
        try {
          const response = await axiosInstance.get(urlLink);
          if (response.status === 200 && isMounted) {
            setAllChecksData(response.data.data);
          } else {
            throw new Error("Failed to fetch brand safety data");
          }
        } catch (error: any) {
          if (isMounted) {
            toast.error(
              error.response?.data?.message ||
                "Error fetching brand safety data, please try again."
            );
          }
        } finally {
          if (isMounted) {
            setLoadingBrandSafety(false);
          }
        }
      }
    };
    getAllOpportunitiesData();
    return () => {
      isMounted = false;
    };
  }, [accessToken, refreshToken, urlLink, refreshAccessToken]);

  //remove from the queued actions
  const [
    showRemoveFromQueuedActionsPopup,
    setShowRemoveFromQueuedActionsPopup,
  ] = useState<boolean>(false);

  //to access the filter applied through the popup
  const [filterList, setFilterList] = useState<string[]>([]);

  // Function to close the dialog
  const closeConfirmDeleteDialog = () => {
    setShowDeletePopup(false);
    setSelectedForDelete(null);
  };

  const closePermanentDeleteDialog = () => {
    setShowPermanentlyDeletePopup(false);
    setSelectedForDelete(null);
  };

  // Function to show a custom toast
  const showCustomToast = (
    backgroundColor: string,
    toastIcon: IconType,
    toastText: string,
    iconColor: string
  ) => {
    toast.custom(
      (t) => (
        <CustomToast
          backgroundColor={backgroundColor}
          toastIcon={toastIcon}
          toastText={toastText}
          iconColor={iconColor}
          t={t}
        />
      ),
      {
        duration: 3000,
      }
    );
  };

  const handleConfirmDelete = useCallback(() => {
    if (selectedForDelete) {
      setBrandSafetyData((prevData) =>
        prevData.filter((item) => item.id !== selectedForDelete.id)
      );
      setArchiveActions((prev) => [...prev, selectedForDelete]);
      showCustomToast(
        "#FFEAEA",
        RiDeleteBinLine,
        "Action archived successfully",
        "#F01F1F"
      );
    }
    setSelectedForDelete(null);
    closeConfirmDeleteDialog();
    if (showFeedbackPopup) {
      setShowFeedbackPopup(false);
      setUserFeedback("");
    }
  }, [selectedForDelete, showFeedbackPopup]);

  const handlePermanentDeleteFromArchive = () => {
    if (selectedForDelete) {
      setArchiveActions((prevData) =>
        prevData.filter((item) => item.id !== selectedForDelete.id)
      );
      showCustomToast(
        "#FFEAEA",
        RiDeleteBinLine,
        "Action deleted permanently",
        "#F01F1F"
      );
    }
    setSelectedForDelete(null);
    setShowPermanentlyDeletePopup(false);
  };

  const handleAddToQueue = useCallback(
    (item: any) => {
      if (!queuedActionsData.some((queuedItem) => queuedItem.id === item.id)) {
        setQueuedActionsData((prevData) => [...prevData, item]);
        setBrandSafetyData((prevData) =>
          prevData.filter((existingItem) => existingItem.id !== item.id)
        );
        setSelectedFromQueue((prevData) => [...prevData, item]);
        showCustomToast(
          "#EAFFF6",
          FaCircleCheck,
          "Successfully added to queue",
          "#2DC485"
        );
      }
    },
    [queuedActionsData]
  );

  const handleRestore = (item: any) => {
    setSelectedForRestore(item);
    setShowRestorePopup(true);
  };

  const closeRestoreDialog = () => {
    setShowRestorePopup(false);
    setSelectedForDelete(null);
  };

  const handleConfirmRestore = () => {
    setBrandSafetyData((prevData) => [...prevData, selectedForRestore]);
    setArchiveActions((prevData) =>
      prevData.filter(
        (existingItem) => existingItem.id !== selectedForRestore.id
      )
    );
    showCustomToast(
      "#EAFFF6",
      FaCircleCheck,
      "Action restored successfully",
      "#2DC485"
    );
    closeRestoreDialog();
  };

  const closeMarkAsIrrelevantPopup = () => {
    setShowMarkAsIrrelevantPopup(false);
  };

  const handleMarkAsIrrelevant = () => {
    setShowMarkAsIrrelevantPopup(false);
    setShowFeedbackPopup(true);
  };

  const handleMarkAsIrrelevantPopup = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-white shadow-lg w-[413px] border border-gray-300"
        style={{
          boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
        }}
      >
        <div className="py-6 px-8">
          <button
            className="py-[6px] pr-[6px]"
            onClick={() => {
              setShowFeedbackPopup(false);
              setSelectedForDelete(null);
            }}
          >
            <IoClose />
          </button>
          <div className="mt-[10px] flex flex-col">
            <p className="text-base font-semibold">
              Please provide feedback to help us improve our recommendations
            </p>
            <textarea
              className="border-[0.5px] my-4 border-gray-300 h-[187px] p-1 text-sm"
              value={userFeedback}
              onChange={(e) => setUserFeedback(e.target.value)}
            />
            <button
              className={`border ${
                userFeedback?.length
                  ? "bg-[#286043] border-[#286043]"
                  : "bg-gray-300 border-gray-300"
              } p-3 flex justify-center items-center gap-1 text-sm leading-4 text-left font-[600]  text-white`}
              onClick={handleConfirmDelete}
              disabled={userFeedback?.length ? false : true}
            >
              Send Feedback
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  // Open the filter popup
  const handleFilter = () => {
    setShowFilterPopup(true);
  };

  // Close the filter popup
  const closeFilterPopup = () => {
    setShowFilterPopup(false);
  };

  const handleClearAllFilters = () => {
    setFilterList([]);
  };

  const removeFilterOneByOne = (item: string) => {
    const result = filterList.filter((element) => element !== item);
    setFilterList(result);
  };

  const handleRemoveFromQueuedActionList = () => {
    const newQueuedActionsData = queuedActionsData.filter(
      (item) => !selectedFromQueue.some((selected) => selected.id === item.id)
    );
    const newBrandSafetyData = [...brandSafetyData, ...selectedFromQueue];
    setQueuedActionsData(newQueuedActionsData);
    setBrandSafetyData(newBrandSafetyData);
    setSelectedFromQueue([]);
    showCustomToast(
      "#FFEAEA",
      RiDeleteBinLine,
      "Action removed successfully",
      "#F01F1F"
    );
    closeRemoveFromQueuedActions();
  };

  const closeRemoveFromQueuedActions = () => {
    setShowRemoveFromQueuedActionsPopup(false);
  };

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="opportunities" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {/* Opportunities Section */}
        {fullName && <UserAvatar />}
        <div className="flex justify-start items-center gap-4 mt-4">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <img
              src={opportunities_logo}
              alt="opportunities logo"
              className="w-[17.5px] h-[17.5px]"
            />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">
              Opportunities
            </p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              keep your opportunities on track
            </p>
          </div>
        </div>

        {/* include all users & filter section */}
        <div
          className={`border border-l-0 border-r-0 border-t-[0.3px] border-b-[0.3px] mt-6 py-4 flex ${
            filterList.length > 0 ? "justify-between" : "justify-end"
          } items-center`}
        >
          {filterList.length > 0 && (
            <ul className="flex items-center gap-2 flex-wrap">
              {filterList.map((item, index) => (
                <li
                  key={index}
                  className="border-[0.5px] p-1 text-xs font-normal flex items-center gap-1"
                >
                  {item}{" "}
                  <button onClick={() => removeFilterOneByOne(item)}>
                    <IoClose />
                  </button>
                </li>
              ))}
            </ul>
          )}
          <div className="flex items-center gap-2">
            <button
              className="border border-[#286043] p-3 flex justify-center items-center gap-1"
              onClick={handleFilter}
            >
              <p className="text-sm leading-4 text-left font-semibold text-[#286043]">
                Filter
              </p>
              <img src={filter_lines} alt="three_lines" className="w-[15px]" />
            </button>
            {filterList.length > 0 && (
              <button
                className="text-sm font-semibold text-[#286043] w-20"
                onClick={handleClearAllFilters}
              >
                Clear All
              </button>
            )}
          </div>
        </div>

        {/* Queued Section */}
        {queuedActionsData.length > 0 ? (
          <ActionSection
            sectionName="Queued Actions"
            actionData={queuedActionsData}
            dataFor="Queued Actions"
            setQueuedActionsData={setQueuedActionsData}
            setSelectedForDelete={setSelectedForDelete}
            onMarkAsIrrelevant={setShowMarkAsIrrelevantPopup}
            selectedFromQueue={selectedFromQueue}
            setSelectedFromQueue={setSelectedFromQueue}
            queuedActionsData={queuedActionsData}
            setShowRemoveFromQueuedActionsPopup={
              setShowRemoveFromQueuedActionsPopup
            }
          />
        ) : (
          <NoActionsFound
            sectionName="Queued Actions"
            errorHeading="No actions found!"
            errorDescription="No actions are currently available. Please check back later for updates."
          />
        )}

        {/* Opportunities Section */}
        {loadingBrandSafety ? (
          <Skeleton />
        ) : brandSafetyData.length > 0 ? (
          <ActionSection
            sectionName="Opportunities"
            actionData={brandSafetyData}
            dataFor="Brand Safety"
            setQueuedActionsData={setQueuedActionsData}
            setShowDeletePopup={setShowDeletePopup}
            setSelectedForDelete={setSelectedForDelete}
            onAddToQueue={handleAddToQueue}
            onMarkAsIrrelevant={setShowMarkAsIrrelevantPopup}
            nextBtnLink={nextBtnLink}
            previousBtnLink={previousBtnLink}
            handleLoadMoreBtn={handleLoadMoreBtn}
            pageCount={pageCount}
            totalRowsCount={allChecksData?.count}
          />
        ) : (
          <NoActionsFound
            sectionName="Opportunities"
            errorHeading="No actions found!"
            errorDescription="No actions are currently available. Please check back later for updates."
          />
        )}

        {/* Archive Section */}
        {/* {archiveActions.length > 0 ? (
          <ActionSection
            sectionName="Archive"
            actionData={archiveActions}
            dataFor="Archive"
            onAddToQueue={handleRestore}
            setSelectedForDelete={setSelectedForDelete}
            setShowDeletePopup={setShowPermanentlyDeletePopup}
          />
        ) : (
          <NoActionsFound sectionName="Archive" />
        )} */}

        {/* Dialog Modals */}
        {showDeletePopup && (
          <DialogComponent
            closeDialog={closeConfirmDeleteDialog}
            dialog_logo={RiDeleteBinLine}
            dialog_heading="Are you sure you want to delete this action?"
            dialogSuccessAction={handleConfirmDelete}
            successBtnText="Yes, Delete it"
            failureBtnText="No, cancel"
            showNote={true}
            action_type="delete"
          />
        )}
        {showPermanentlyDeletePopup && (
          <DialogComponent
            closeDialog={closePermanentDeleteDialog}
            dialog_logo={RiDeleteBinLine}
            dialog_heading="Are you sure you want to permanently delete this action?"
            dialogSuccessAction={handlePermanentDeleteFromArchive}
            successBtnText="Yes, Delete it"
            failureBtnText="No, cancel"
            showNote={false}
            action_type="delete"
          />
        )}
        {showMarkAsIrrelevantPopup && (
          <DialogComponent
            closeDialog={closeMarkAsIrrelevantPopup}
            dialog_logo={RiDeleteBinLine}
            dialog_heading="Are you sure you want to mark it as irrelevant?"
            dialogSuccessAction={handleMarkAsIrrelevant}
            successBtnText="Yes, Mark it"
            failureBtnText="No, cancel"
            showNote={true}
            action_type="delete"
          />
        )}

        {showRestorePopup && (
          <DialogComponent
            closeDialog={closeRestoreDialog}
            dialog_logo={MdRestore}
            dialog_heading="Are you sure you want to restore this action?"
            dialogSuccessAction={handleConfirmRestore}
            successBtnText="Yes, Restore it"
            failureBtnText="No, cancel"
            showNote={false}
            action_type="restore"
          />
        )}
        {showRemoveFromQueuedActionsPopup && (
          <DialogComponent
            closeDialog={closeRemoveFromQueuedActions}
            dialog_logo={RiDeleteBinLine}
            dialog_heading="Are you sure you want to remove this action?"
            dialogSuccessAction={handleRemoveFromQueuedActionList}
            successBtnText="Yes, Remove it"
            failureBtnText="No, cancel"
            showNote={false}
            action_type="delete"
          />
        )}
        {showFeedbackPopup && handleMarkAsIrrelevantPopup()}
        <Suspense fallback={<Loader />}>
          <FilterPopup
            isOpen={showFilterPopup}
            onClose={closeFilterPopup}
            setFilterList={setFilterList}
          />
        </Suspense>
      </div>
    </div>
  );
};

export default Opportunities;
