import React, { lazy, Suspense, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import filter_lines from "../assets/filter_lines.png";
import { IoClose, IoFilterOutline } from "react-icons/io5";
import campaign_page_log from "../assets/campaign_page_logo.png";
import campaign_data from "../json/tables/Campaigns.json";
import Loader from "../components/statusIndicator/loader";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import UserAvatar from "../components/userAvatar/UserAvatar";
import { Campaign } from "../interfaces/campaigns";
import SkeletonTable from "../components/Skeleton";

const CampaignsTable = lazy(() => import("../components/CampaignsTable"));
const CampaignFilterPopup = lazy(
  () => import("../components/CampaignsFilterPopup")
);

type SortOption = "Trader Name" | "Budget" | "Health Score";

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const Campaigns: React.FC = () => {
  const [showFilterPopup, setShowFilterPopup] = useState<boolean>(false);
  const [filterList, setFilterList] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredCampaigns, setFilteredCampaigns] = useState<Campaign[]>();
  const [sortOption, setSortOption] = useState<SortOption>("Budget");
  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();
  const [loadTable, setLoadTable] = useState<boolean>(false);
  const navigate = useNavigate();
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const renderCampaignsTable = () => {
    setLoadTable(true);
    setTimeout(() => {
      setFilteredCampaigns(campaign_data);
      setLoadTable(false);
    }, 300);
  };

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    } else {
      renderCampaignsTable();
    }
  }, [accessToken, refreshToken, navigate]);

  useEffect(() => {
    let results = campaign_data.filter((campaign) =>
      campaign.campaign
        .toLowerCase()
        .includes(debouncedSearchQuery.toLowerCase())
    );

    const sortFunctions: Record<SortOption, (a: any, b: any) => any> = {
      "Trader Name": (a, b) =>
        a.settings.trader.localeCompare(b.settings.trader),
      Budget: (a, b) => b.settings.budget - a.settings.budget,
      "Health Score": (a, b) => a.health_score - b.health_score,
    };

    results.sort(sortFunctions[sortOption]);

    setFilteredCampaigns(results);
  }, [debouncedSearchQuery, sortOption]);

  const closeFilterPopup = () => {
    setShowFilterPopup(false);
  };

  const removeFilterOneByOne = (item: string) => {
    const result = filterList.filter((element) => element !== item);
    setFilterList(result);
  };

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="campaigns" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        <div className="flex justify-start items-center gap-4 mt-4">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#FFF8E4]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #FCD447 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <img
              src={campaign_page_log}
              alt="opportunities logo"
              className="w-[17.5px] h-[17.5px]"
            />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">Campaigns</p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              keep your campaigns on track
            </p>
          </div>
        </div>
        <div className="border border-l-0 border-r-0 border-t-[0.3px] border-b-[0.3px] mt-6 py-4 flex justify-between items-center">
          <div className="flex items-center gap-3">
            <input
              type="search"
              id="search_campaigns"
              className="text-gray-600 h-12 border-gray-500 border-[0.5px] outline-none p-2 text-xs w-64"
              placeholder="Search campaigns"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-end gap-4">
            <div className="flex items-center justify-end gap-1 text-sm leading-4 text-left font-semibold border border-[#286043] p-3 h-12">
              <IoFilterOutline size={18} />
              <p className="text-sm leading-4 text-left font-semibold">
                Sort By :
              </p>
              <select
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value as SortOption)}
                className="h-11 outline-none cursor-pointer"
              >
                <option value="Trader Name">Trader Name</option>
                <option value="Budget">Budget</option>
                <option value="Health Score">Health Score</option>
              </select>
            </div>
            <button
              className="border border-[#286043] p-3 flex justify-center items-center gap-1 h-12"
              onClick={() => setShowFilterPopup(true)}
            >
              <p className="text-sm leading-4 text-left font-[600] text-[#286043]">
                Filter
              </p>
              <img src={filter_lines} alt="three_lines" className="w-[15px]" />
            </button>
          </div>
        </div>
        {filterList.length > 0 && (
          <div className="flex justify-between my-3 gap-3 items-center">
            <ul className="flex items-center gap-2 flex-wrap">
              {filterList.map((item, index) => (
                <li
                  key={index}
                  className="border-[0.5px] p-1 text-xs font-normal flex items-center gap-1"
                >
                  {item}{" "}
                  <button onClick={() => removeFilterOneByOne(item)}>
                    <IoClose />
                  </button>
                </li>
              ))}
            </ul>
            <button
              className="text-sm font-semibold text-[#286043] border border-[#286043] p-3 h-[42px] max-w-24 min-w-24"
              onClick={() => setFilterList([])}
            >
              Clear All
            </button>
          </div>
        )}
        {/* campaigns table section */}
        {loadTable ? (
          <SkeletonTable />
        ) : (
          filteredCampaigns?.length && (
            <Suspense fallback={<Loader />}>
              <CampaignsTable tableData={filteredCampaigns} />
            </Suspense>
          )
        )}
        {showFilterPopup && (
          <Suspense fallback={<Loader />}>
            <CampaignFilterPopup
              isOpen={showFilterPopup}
              onClose={closeFilterPopup}
              setFilterList={setFilterList}
            />
          </Suspense>
        )}
      </div>
    </div>
  );
};

export default Campaigns;
