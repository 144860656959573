import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import page_layout from "../json/pageJson/myConnectors.json";
import all_connectors from "../json/connectorLibrary.json";
import ConnectorsItemComp from "../components/connectorsItemComp";
import connector_lib_logo from "../assets/connectors_library.webp";
import UserAvatar from "../components/userAvatar/UserAvatar";

const ConnectorLibrary: React.FC = () => {
  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    }
  }, [accessToken, refreshToken, navigate]);

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="connectors library" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        <div className="flex justify-start items-center gap-4 mt-4">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <img
              src={connector_lib_logo}
              alt="connectors library logo"
              className="w-[17.5px] h-[17.5px]"
            />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">
              {page_layout.connectorLibrary_heading}
            </p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              {page_layout.connectorLibrary_description}
            </p>
          </div>
        </div>
        {/* Connecters Library Heading and search */}
        <div className="mt-12">
          <p className="text-lg font-[700] text-left">
            {page_layout.connectorLibrary_sub_heading}
          </p>
        </div>
        {/* Connectors Library components Section */}
        <div className="flex justify-start items-center gap-4 mt-8 flex-wrap">
          {all_connectors.map((connector, index) => (
            <ConnectorsItemComp connector={connector} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ConnectorLibrary;
