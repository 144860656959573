import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import UserAvatar from "../components/userAvatar/UserAvatar";
import { FaUsers } from "react-icons/fa6";
import NoActionsFound from "../components/noActionsFound";
import userData from "../json/tables/Users.json";
import UserTable from "../components/userPage/UserTable";
import { Users } from "../interfaces/usersPage";
import CreateUser from "../components/userPage/CreateUser";

const User: React.FC = () => {
  const [showCreateUserPopup, setShowCreateUserPopup] =
    useState<boolean>(false);
  const [usersTableData, setUserTableData] = useState<Users[]>(
    userData as Users[]
  );

  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    }
  }, [accessToken, refreshToken, navigate]);

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="manage users" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        {/* Heading Section */}
        <div className="flex justify-start items-center gap-4 mt-4 border-b-[0.75px] pb-[21px]">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <FaUsers size={20} />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">Users</p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              Check All Users
            </p>
          </div>
        </div>
        {/* Users List Section */}
        <div>
          {usersTableData.length > 0 ? (
            <div>
              <div className="flex justify-between items-center">
                <p className="text-gray-900 text-base leading-5 text-left font-bold pt-6 pb-6">
                  Users
                  {usersTableData.length > 0
                    ? `(${usersTableData.length})`
                    : ""}
                </p>
                <button
                  className="border-[0.75px] border-[#286043] bg-[#286043] text-white text-sm font-semibold py-[6px] px-3"
                  onClick={() => setShowCreateUserPopup(true)}
                >
                  Create User
                </button>
              </div>

              <div className="border-[0.75px] border-gray-300 py-6 px-4">
                <UserTable tableData={usersTableData} />{" "}
                {/* Pass tableData as a prop */}
              </div>
            </div>
          ) : (
            <NoActionsFound
              sectionName="Users"
              errorHeading="No users found!"
              showActionButton={false}
              errorDescription="There are no users available, Please retry or add new users."
              actionButtonText="Create User"
              actionButtonRedirection=""
            />
          )}
          {showCreateUserPopup && (
            <CreateUser handleClose={setShowCreateUserPopup} />
          )}
        </div>
      </div>
    </div>
  );
};

export default User;
