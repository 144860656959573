import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import UserAvatar from "../components/userAvatar/UserAvatar";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import Insertion_order_demo_data from "../json/tables/taxonomy/InsertionOrder.json";
import LineItem_demo_data from "../json/tables/taxonomy/LineItem.json";
import Creative_demo_data from "../json/tables/taxonomy/Creative.json";
import TaxonomyTable from "../components/taxonomy/TaxonomyTable";
import { useProfile } from "../context/ProfileContext";

const Taxonomy: React.FC = () => {
  const { isAdmin } = useProfile();
  const [selectTableType, setSelectTableType] =
    useState<string>("Insertion Order");
  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    }
  }, [accessToken, refreshToken, navigate]);

  const getTableData = () => {
    switch (selectTableType) {
      case "Insertion Order":
        return Insertion_order_demo_data.data;
      case "Line Item":
        return LineItem_demo_data.data;
      case "Creative":
        return Creative_demo_data.data;
      default:
        return Insertion_order_demo_data.data;
    }
  };

  useEffect(() => {
    getTableData();
  }, [selectTableType]);

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="taxonomy alerts" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        {/* Heading Section */}
        <div className="flex justify-start items-center gap-4 mt-4 border-b-[0.75px] pb-[21px]">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <MdOutlineDriveFileRenameOutline size={20} />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">
              Taxonomy Alerts
            </p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              Check taxonomy alerts and validate the naming structures
            </p>
          </div>
        </div>
        <div className="flex justify-between items-center">
          {/* Table Selector */}
          <div className="flex gap-2 mt-6">
            {["Insertion Order", "Line Item", "Creative"].map((item) => (
              <button
                key={item}
                className={`border p-2 text-xs ${
                  selectTableType === item ? "bg-gray-200" : ""
                }`}
                onClick={() => setSelectTableType(item)}
              >
                {item}
              </button>
            ))}
          </div>
        </div>
        {/* Render TaxonomyTable with corrected data */}
        <TaxonomyTable tableData={getTableData()} />
      </div>
    </div>
  );
};

export default Taxonomy;
