import React, { useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import ActionItemComponent from "./actionItemComponent";
import CriticalityChips from "./CriticalityChips";
import { Checks } from "../interfaces/brandSafety";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import CHIPS_COLORS from "../constants/chipsColorConstants";
import { Opportunities } from "../interfaces/campaigns";

interface ActionsSectionProps {
  sectionName: string;
  actionData?: Checks[];
  dataFor?: string;
  setQueuedActionsData?: React.Dispatch<React.SetStateAction<Checks[]>>;
  setShowDeletePopup?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedForDelete?: React.Dispatch<React.SetStateAction<Checks>>;
  onAddToQueue?: (item: any) => void;
  onMarkAsIrrelevant?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFromQueue?: Checks[];
  setSelectedFromQueue?: React.Dispatch<React.SetStateAction<Checks[]>>;
  queuedActionsData?: Checks[];
  handleRemoveFromQueuedActionList?: () => void;
  setShowRemoveFromQueuedActionsPopup?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  nextBtnLink?: string | null;
  previousBtnLink?: string | null;
  handleLoadMoreBtn?: (btnText: string) => void;
  pageCount?: string;
  totalRowsCount?: number;
}

const ActionSection: React.FC<ActionsSectionProps> = ({
  sectionName,
  actionData,
  dataFor,
  setQueuedActionsData,
  setShowDeletePopup,
  setSelectedForDelete,
  onAddToQueue,
  onMarkAsIrrelevant,
  selectedFromQueue,
  setSelectedFromQueue,
  queuedActionsData,
  handleRemoveFromQueuedActionList,
  setShowRemoveFromQueuedActionsPopup,
  nextBtnLink,
  previousBtnLink,
  handleLoadMoreBtn,
  pageCount,
  totalRowsCount,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const renderCount = (criticality: number): number => {
    const resultArr = actionData?.filter(
      (item) => item.campaign_checks.data[0].threat === criticality
    );
    return resultArr?.length || 0;
  };

  const handleRemoveFromQueue = () => {
    if (setShowRemoveFromQueuedActionsPopup) {
      setShowRemoveFromQueuedActionsPopup(true);
    }
  };

  const handleSelectRemoveAllFromQueuedActionsTable = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      handleAddAllActionsToSelectedFromQueue();
    } else {
      handleRemoveAllActionsFromSelectedFromQueue();
    }
  };

  const handleAddAllActionsToSelectedFromQueue = () => {
    if (setSelectedFromQueue && queuedActionsData) {
      setSelectedFromQueue(queuedActionsData);
    }
  };

  const handleRemoveAllActionsFromSelectedFromQueue = () => {
    if (setSelectedFromQueue) setSelectedFromQueue([]);
  };

  const onClickLoadMore = (text: string) => {
    if (handleLoadMoreBtn) {
      handleLoadMoreBtn(text);
    }
  };

  const renderOpportunities = (data: Opportunities) => {
    const chipsData = [
      {
        type: "Threats",
        count: renderCount(1),
        styles: CHIPS_COLORS.threats,
      },
      {
        type: "Warnings",
        count: renderCount(2),
        styles: CHIPS_COLORS.warnings,
      },
      {
        type: "Anomalies",
        count: renderCount(4),
        styles: CHIPS_COLORS.anomalies,
      },
    ];
    return (
      <div className="flex items-center gap-2">
        {chipsData.map((chip, index) => (
          <CriticalityChips
            key={index}
            type={chip.type}
            count={chip.count}
            style={chip.styles}
          />
        ))}
      </div>
    );
  };

  return (
    <div>
      <p className="text-gray-900 text-base leading-5 text-left font-bold pt-6 pb-6">
        {sectionName}
      </p>
      <div className="border-[0.75px] border-gray-300 py-6 px-4">
        {dataFor === "Brand Safety" && (
          <div
            className="cursor-pointer text-sm leading-5 text-left font-bold text-gray-900 flex items-center gap-1 py-4"
            onClick={handleToggle}
          >
            <p>
              {dataFor} ({actionData?.length}){" "}
            </p>
            <span>
              {isExpanded ? (
                <IoIosArrowUp size={15} />
              ) : (
                <IoIosArrowDown size={15} />
              )}
            </span>
          </div>
        )}
        {dataFor === "Brand Safety" && isExpanded === false && (
          <div className="flex items-center gap-2">
            {renderOpportunities({} as Opportunities)}{" "}
          </div>
        )}
        {(dataFor !== "Brand Safety" || isExpanded) && (
          <div className="pt-4">
            {actionData && actionData.length > 0 ? (
              <>
                <div className="flex justify-between items-center text-gray-900 text-xs leading-4 text-left font-bold border-[0.5px] p-3 border-gray-300 bg-gray-100">
                  <div className="w-2/6 flex items-center gap-2">
                    {dataFor === "Queued Actions" && (
                      <input
                        type="checkbox"
                        onClick={(e) => e.stopPropagation()}
                        className="form-checkbox h-4 w-4 text-gray-600 border-gray-500 checked:bg-gray-900 hover:bg-gray-200 outline-none"
                        checked={
                          selectedFromQueue?.length ===
                          queuedActionsData?.length
                        }
                        onChange={(e) =>
                          handleSelectRemoveAllFromQueuedActionsTable(e)
                        }
                      />
                    )}
                    <p>RECOMMENDED ACTIONS</p>
                  </div>
                  <div className="flex w-4/6 justify-between items-center ml-6">
                    <p className="w-36">CAMPAIGN</p>
                    <p className="">UPDATED DATE</p>
                    <p>CRITICALITY</p>
                    {dataFor !== "Queued Actions" && (
                      <p className="">ACTIONS</p>
                    )}
                  </div>
                </div>
                {actionData.map((item: any, index: number) => (
                  <ActionItemComponent
                    key={index}
                    item={item}
                    setQueuedActionsData={setQueuedActionsData}
                    dataFor={dataFor}
                    setShowDeletePopup={setShowDeletePopup}
                    setSelectedForDelete={setSelectedForDelete}
                    onAddToQueue={onAddToQueue}
                    onMarkAsIrrelevant={onMarkAsIrrelevant}
                    selectedFromQueue={selectedFromQueue}
                    setSelectedFromQueue={setSelectedFromQueue}
                  />
                ))}
                {dataFor === "Queued Actions" && (
                  <div className=" mt-4 flex justify-end">
                    <button
                      className={`${
                        (selectedFromQueue?.length ?? 0) > 0
                          ? "border border-[#286043] bg-[#286043]"
                          : "bg-gray-100"
                      } p-2 text-white text-sm leading-5 font-[600]`}
                      disabled={
                        (selectedFromQueue?.length ?? 0) > 0 ? false : true
                      }
                    >
                      Add to ToDo
                    </button>
                    <button
                      className={`p-2 ${
                        (selectedFromQueue?.length ?? 0) > 0
                          ? "text-[#286043]"
                          : "text-gray-300"
                      } text-sm leading-5 font-[600]`}
                      disabled={
                        (selectedFromQueue?.length ?? 0) > 0 ? false : true
                      }
                      onClick={handleRemoveFromQueue}
                    >
                      Remove
                    </button>
                  </div>
                )}
              </>
            ) : (
              <p className="text-gray-600">No data available</p>
            )}
            {/* next previous btn container */}
            {dataFor === "Brand Safety" && (
              <div className="flex gap-2 justify-between mt-5">
                <div>
                  <div className="border border-[#286043] p-3 flex justify-center items-center gap-1">
                    <p className="text-sm leading-4 text-left font-semibold text-[#286043]">
                      Page : {pageCount} of{" "}
                      {totalRowsCount &&
                        Math.round(Math.ceil(totalRowsCount / 10))}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  {Number(pageCount) > 2 && (
                    <button
                      className="border bg-[#286043] border-[#286043] p-3 flex justify-center items-center gap-1"
                      onClick={() => onClickLoadMore("first_page")}
                    >
                      <span className="text-sm leading-4 text-left font-[600]  text-white">
                        <MdKeyboardDoubleArrowLeft />
                      </span>
                    </button>
                  )}
                  {previousBtnLink !== null && (
                    <button
                      className="border bg-[#286043] border-[#286043] p-3 flex justify-center items-center gap-1"
                      onClick={() => onClickLoadMore("previous")}
                    >
                      <span className="text-sm leading-4 text-left font-[600]  text-white">
                        Previous Page
                      </span>
                    </button>
                  )}
                  {nextBtnLink !== null && (
                    <button
                      className="border bg-[#286043] border-[#286043] p-3 flex justify-center items-center gap-1"
                      onClick={() => onClickLoadMore("next")}
                    >
                      <span className="text-sm leading-4 text-left font-[600]  text-white">
                        Next Page
                      </span>
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionSection;
