import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import UserAvatar from "../components/userAvatar/UserAvatar";
import ProductsTiles from "../components/productsPage/ProductsTiles";
import product_tiles_list from "../json/products/products.json";
import { AiFillProduct } from "react-icons/ai";
import { useProfile } from "../context/ProfileContext";

const Products: React.FC = () => {
  const { isAdmin } = useProfile();
  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    }
  }, [accessToken, refreshToken, navigate]);

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="manage products" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        {/* Heading Section */}
        <div className="flex justify-start items-center gap-4 mt-4 border-b-[0.75px] pb-[21px]">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <AiFillProduct size={20} />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">Products</p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              Dashboard
            </p>
          </div>
        </div>
        {/* products tiles component */}
        <div className="mt-6 flex gap-2 flex-wrap">
          {product_tiles_list
            .filter((product) => {
              if (isAdmin) return true;
              return product.isUserAuthorized;
            })
            .map((product) => (
              <ProductsTiles key={product.product_heading} product={product} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Products;
