import React, { useEffect, useState } from "react";
import MultiSelectDropdown from "../MultiSelectDropdown";
import SingleSelectDropdown from "../SingleSelectDropdown";
import { useGoogleLogin, CodeResponse } from "@react-oauth/google";
import { Link, useNavigate } from "react-router-dom";
import { IoChevronBack, IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import debounce from "lodash/debounce";
import report_type from "../../json/reports/reports.json";
import axiosInstance from "../../utils/axiosInstance";
import Loader from "../statusIndicator/loader";
import GreenTick from "../statusIndicator/greenTick";
import ScheduleSettings from "../SchedulerSettings";
import Google_logo from "../../assets/google_logo.svg";

interface SchedulingUserInput {
  interval?: string;
  period?: string;
  minute?: number;
  days_of_week?: string[];
  hour?: number;
  day?: number;
  month?: number;
  time_zone?: string;
}

interface userInputProps {}

const UserInputForDV360: React.FC<userInputProps> = () => {
  const [configName, setConfigName] = useState<string>("");
  const [atomicAdsChecked, setAtomicAdsChecked] = useState<boolean>(true);
  const [warehouseChecked, setWarehouseChecked] = useState<boolean>(false);

  const [partners, setPartners] = useState<string[]>([]);
  const [fetchedPartnersList, setFetchedPartnerList] = useState<
    { id: string; name: string }[]
  >([]);
  const [partnersLoading, setPartnersLoading] = useState<boolean>(false);

  const [advertisers, setAdvertisers] = useState<string[]>([]);
  const [fetchedAdvertisers, setFetchedAdvertisers] = useState<
    { id: string; name: string }[]
  >([]);

  const [advertisersLoading, setAdvertisersLoading] = useState<boolean>(false);
  const [metrics, setMetrics] = useState<string[]>([]);
  const [dimensions, setDimensions] = useState<string[]>([]);

  const [reportType, setReportType] = useState<string | null>(null);
  const [historicalSyncTimeFrame, setHistoricalSyncTimeFrame] = useState<
    string | null
  >(null);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [authError, setAuthError] = useState<string | null>(null);
  const [isSaveAndTestActive, setIsSaveAndTestActive] =
    useState<boolean>(false);
  const [saveAndTestLoader, setSaveAndTestLoader] = useState<boolean>(false);
  const [authLoading, setAuthLoading] = useState<boolean>(false);
  const [externalId, setExternalId] = useState<string>("");
  const [scope, setScope] = useState<string>("");
  const [createNewConfigChecked, setCreateNewConfigChecked] = useState(true);
  const [queryId, setQueryId] = useState<string>("");

  // scheduler
  const [openSchedule, setOpenSchedule] = useState<boolean>(false);
  const [schedulingUserInput, setSchedulingUserInput] =
    useState<SchedulingUserInput>({});
  const [schedulePreview, setSchedulePreview] = useState<string>("");

  useEffect(() => {
    if (schedulePreview !== "") {
      setOpenSchedule(false);
    }
  }, [schedulePreview]);

  const navigate = useNavigate();

  const getMetrics = () => {
    switch (reportType) {
      case "Standard":
        return report_type.standard_report.metrics.map(
          (item) => item.display_name
        );
      case "Floodlight":
        return report_type.floodlight_report.metrics.map(
          (item) => item.display_name
        );
      case "Youtube":
        return report_type.youtube_report.metrics.map(
          (item) => item.display_name
        );
      default:
        return [];
    }
  };

  const getDimensions = () => {
    switch (reportType) {
      case "Standard":
        return report_type.standard_report.dimensions.map(
          (item) => item.display_name
        );
      case "Floodlight":
        return report_type.floodlight_report.dimensions.map(
          (item) => item.display_name
        );
      case "Youtube":
        return report_type.youtube_report.dimensions.map(
          (item) => item.display_name
        );
      default:
        return [];
    }
  };

  const getPartnersData = async () => {
    setPartnersLoading(true);
    try {
      const response = await axiosInstance.get(`/connector/dv-360/partners/`);
      if (response.status === 200) {
        setFetchedPartnerList(response.data.data.partners);
        setPartnersLoading(false);
      } else {
        setPartnersLoading(false);
        throw new Error("Unexpected response while fetching partners");
      }
    } catch (error: any) {
      setPartnersLoading(false);
      toast.error(
        error.response?.data?.message ||
          "Error fetching partners, please try again."
      );
    }
  };

  const getDV360Auth = async (code: string) => {
    setAuthLoading(true);
    try {
      const response = await axiosInstance.post(
        `/connector/google-dv360-oauth/`,
        {
          code: code,
          configuration_name: configName,
        }
      );

      if (response.status === 200) {
        setExternalId(response.data.data.external_id);
        setIsAuthorized(true);
        setAuthError(null);
        setIsSaveAndTestActive(true);
        setAuthLoading(false);
        getPartnersData();
      } else {
        setAuthLoading(false);
        toast.error("Auth Error");
        setIsAuthorized(false);
        setAuthError("Authorization failed. Please try again.");
        setIsSaveAndTestActive(false);
      }
    } catch (error) {
      console.error(error);
      setAuthLoading(false);
      toast.error("Authentication Error, Please Retry");
      setIsAuthorized(false);
      setAuthError("Authorization failed. Please try again.");
      setIsSaveAndTestActive(false);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (
      tokenResponse: Omit<
        CodeResponse,
        "error" | "error_description" | "error_uri"
      >
    ) => {
      getDV360Auth(tokenResponse.code);
    },
    onError: (
      error: Pick<CodeResponse, "error" | "error_description" | "error_uri">
    ) => {
      console.error("Google login failed", error);
      setIsAuthorized(false);
      setAuthError("Authorization failed. Please try again.");
      setIsSaveAndTestActive(false);
    },
    flow: "auth-code",
    scope: scope,
    prompt: "consent",
    access_type: "offline",
  } as any);

  // with component load scope will be fetched
  useEffect(() => {
    getScope();
  }, []);

  const getScope = async () => {
    setAuthLoading(true);
    try {
      const response = await axiosInstance.get(
        `/connector/google-dv360-oauth/`
      );

      if (response.status === 200) {
        setScope(response.data.data.scope.join(" "));
        setAuthLoading(false);
      }
    } catch (error) {
      setAuthLoading(false);
    }
  };

  const handleAuthorizeClick = () => {
    setAuthLoading(true);
    login();
  };

  const getAdvertisersList = async (partnersIds: string[]) => {
    setAdvertisersLoading(true);
    try {
      const advertisersPromises = partnersIds.map(async (id) => {
        const response = await axiosInstance.get(
          `/connector/dv-360/advertisers/${id}/`
        );

        if (response.status === 200) {
          return response.data.data.advertisers;
        } else {
          throw new Error("Unexpected response while fetching advertisers");
        }
      });

      const results = await Promise.all(advertisersPromises);
      const allAdvertisers = results.flat();
      setFetchedAdvertisers(allAdvertisers);
    } catch (error: any) {
      console.error("Error fetching advertisers:", error);
      toast.error(
        error.response?.data?.message ||
          "Error fetching advertisers, please try again."
      );
    } finally {
      setAdvertisersLoading(false);
    }
  };

  const handlePartnersChange = () => {
    if (partners.length > 0) {
      const selectedPartnersIds = partners.map((accountName) => {
        const partner = fetchedPartnersList.find(
          (partner) => partner.name === accountName
        );
        return partner ? partner.id : null;
      });

      const validPartnersIds = selectedPartnersIds.filter(
        (id): id is string => id !== null
      );

      if (validPartnersIds.length > 0) {
        getAdvertisersList(validPartnersIds);
      } else {
        setFetchedAdvertisers([]);
      }
    } else {
      setFetchedAdvertisers([]);
    }
  };

  const debouncedHandleAccountChange = debounce(handlePartnersChange, 300);

  useEffect(() => {
    debouncedHandleAccountChange();
    return () => {
      debouncedHandleAccountChange.cancel();
    };
  }, [partners]);

  const getPartnersIdList = () => {
    const result_arr = partners
      .map((itemName) => {
        const found = fetchedPartnersList.find(
          (item) => item.name === itemName
        );
        return found ? found.id : "";
      })
      .filter((id) => id !== "");
    return result_arr;
  };

  const getAdvertisersIdList = () => {
    const result_arr = advertisers
      .map((itemName) => {
        const found = fetchedAdvertisers.find((item) => item.name === itemName);
        return found ? found.id : "";
      })
      .filter((id) => id !== "");
    return result_arr;
  };

  const getMetricsIDList = () => {
    switch (reportType) {
      case "Standard":
        return report_type.standard_report.metrics
          .filter((item) => metrics.includes(item.display_name))
          .map((item) => item.backend_name);
      case "Floodlight":
        return report_type.floodlight_report.metrics
          .filter((item) => metrics.includes(item.display_name))
          .map((item) => item.backend_name);
      case "Youtube":
        return report_type.youtube_report.metrics
          .filter((item) => metrics.includes(item.display_name))
          .map((item) => item.backend_name);
      default:
        return [];
    }
  };

  const getDimensionsIDList = () => {
    switch (reportType) {
      case "Standard":
        return report_type.standard_report.dimensions
          .filter((item) => dimensions.includes(item.display_name))
          .map((item) => item.backend_name);
      case "Floodlight":
        return report_type.floodlight_report.dimensions
          .filter((item) => dimensions.includes(item.display_name))
          .map((item) => item.backend_name);
      case "Youtube":
        return report_type.youtube_report.dimensions
          .filter((item) => dimensions.includes(item.display_name))
          .map((item) => item.backend_name);
      default:
        return [];
    }
  };

  const getDateRange = (input: string) => {
    const str = "LAST_" + input.replace(" ", "_").toUpperCase();
    return str;
  };

  const handleSaveAndTest = async () => {
    let requestData;
    if (createNewConfigChecked) {
      if (
        partners.length === 0 ||
        advertisers.length === 0 ||
        !reportType ||
        metrics.length === 0 ||
        dimensions.length === 0 ||
        !historicalSyncTimeFrame ||
        !schedulingUserInput.time_zone
      ) {
        toast.error("Fill all fields");
        return;
      }
      requestData = {
        display_name: configName,
        partners: getPartnersIdList(),
        advertiser_ids: getAdvertisersIdList(),
        report_type: reportType.toUpperCase().replace(" ", "_"),
        metrics: getMetricsIDList(),
        dimensions: getDimensionsIDList(),
        date_range: getDateRange(historicalSyncTimeFrame),
        external_id: externalId,
        schedule_settings: schedulingUserInput,
      };
    } else {
      if (!queryId || !schedulingUserInput.time_zone) {
        toast.error("Fill all fields");
        return;
      }
      requestData = {
        display_name: configName,
        query_id: queryId,
        external_id: externalId,
        schedule_settings: schedulingUserInput,
      };
    }

    setSaveAndTestLoader(true);
    try {
      const response = await axiosInstance.post(
        `/connector/dv-360/query/`,
        requestData
      );

      if (response.status === 200) {
        toast.success("Configuration checked successfully!");
        setSaveAndTestLoader(false);
        navigate("/validate-connector", { state: { page: "dv360" } });
      } else {
        setSaveAndTestLoader(false);
        throw new Error("Unexpected response while checking configuration");
      }
    } catch (error: any) {
      setSaveAndTestLoader(false);
      console.error("Error checking configuration:", error);
      toast.error(
        error.response?.data?.message ||
          "Error checking configuration, please try again."
      );
    }
    setTimeout(() => {
      setSaveAndTestLoader(false);
    }, 2000);
  };

  useEffect(() => {
    if (reportType) {
      setDimensions([]);
      setMetrics([]);
    }
  }, [reportType]);

  return (
    <div className="mb-[250px]">
      <div onScroll={(e) => e.stopPropagation()}>
        <div className="mt-4 flex flex-col">
          {/* Configuration Name */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Configuration Name</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-[42px] text-sm"
              placeholder="Configuration Name..."
              value={configName}
              onChange={(e) => setConfigName(e.target.value)}
              disabled={isAuthorized} // Disable if authorized
            />
          </div>

          {/* Destination */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Destination</h3>
            <div className="flex items-center gap-3">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="atomic-ads"
                  className="border outline-none p-3 w-4 h-4"
                  checked={atomicAdsChecked}
                  onChange={() => setAtomicAdsChecked(!atomicAdsChecked)}
                />
                <label htmlFor="atomic-ads" className="ml-2 text-sm">
                  Atomic Ads
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="warehouse"
                  className="border outline-none p-3 w-4 h-4"
                  checked={warehouseChecked}
                  disabled
                />
                <label
                  htmlFor="warehouse"
                  className="ml-2 text-sm text-gray-500"
                >
                  Warehouse
                </label>
              </div>
            </div>
          </div>

          {/* Authorize Connection Button */}
          <div className="mb-6">
            <button
              className={`border-[0.75px] ${
                configName
                  ? "border-[#286043] text-[#286043]"
                  : "border-gray-300 text-gray-300"
              } text-sm font-semibold py-[6px] px-3 w-fit flex items-center`}
              onClick={handleAuthorizeClick}
              disabled={!configName || isAuthorized || authLoading}
            >
              {authLoading ? (
                <div className="flex gap-2 items-center">
                  <Loader />
                  Authorizing...
                </div>
              ) : isAuthorized ? (
                <>
                  <GreenTick />
                  Authorized
                </>
              ) : (
                <>
                  <img
                    src={Google_logo}
                    alt="google"
                    className="h-6 w-auto mr-1"
                  />
                  Authorize
                </>
              )}
            </button>

            {/* Error Message */}
            {authError && (
              <p className="text-xs text-red-500 mt-2">{authError}</p>
            )}
          </div>

          {/* Report Configuration Method */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">
              Report configuration method
            </h3>
            <div className="border py-6 px-4 max-w-[384px]">
              <div className="flex items-start pb-4">
                <input
                  type="checkbox"
                  id="create-new-config"
                  className="border outline-none p-3 w-4 h-4"
                  checked={createNewConfigChecked}
                  onChange={() => {
                    setCreateNewConfigChecked(true);
                  }}
                  disabled={!isAuthorized}
                />
                <label
                  htmlFor="create-new-config"
                  className="ml-2 text-sm flex flex-col"
                >
                  <span className="text-sm font-semibold">
                    Create new Configuration
                  </span>
                  <span className="text-xs font-normal">
                    Create a custom report configuration
                  </span>
                </label>
              </div>
              <div className="flex items-start border-t-[0.5px] pt-4">
                <input
                  type="checkbox"
                  id="reuse-existing-config"
                  className="border outline-none p-3 w-[20px] h-4"
                  checked={!createNewConfigChecked}
                  onChange={() => {
                    setCreateNewConfigChecked(false);
                  }}
                  disabled={!isAuthorized}
                />
                <label
                  htmlFor="reuse-existing-config"
                  className="ml-2 text-sm text-gray-500 flex flex-col"
                >
                  <span className="text-sm font-semibold">
                    Reuse an existing report configuration
                  </span>
                  <span className="text-xs font-normal">
                    Reuse an existing report configuration from your Google
                    Display & Video 360 account.
                  </span>
                </label>
              </div>
            </div>
          </div>

          {createNewConfigChecked ? (
            <div>
              {/* Partners */}
              <div className="mb-6">
                <h3 className="text-sm font-semibold mb-4">Partners</h3>
                {partnersLoading ? (
                  <div className="flex items-center text-xs">
                    <Loader />
                    Loading Partners...
                  </div>
                ) : (
                  <MultiSelectDropdown
                    items={fetchedPartnersList.map((element) => element.name)}
                    selectedItems={partners}
                    setSelectedItems={setPartners}
                    disabled={!isAuthorized}
                  />
                )}
              </div>

              {/* Advertisers */}
              <div className="mb-6">
                <h3 className="text-sm font-semibold mb-4">Advertisers</h3>
                {advertisersLoading ? (
                  <div className="flex items-center text-xs">
                    <Loader />
                    Loading Advertisers...
                  </div>
                ) : (
                  <MultiSelectDropdown
                    items={fetchedAdvertisers.map(
                      (advertiser) => advertiser.name
                    )}
                    selectedItems={advertisers}
                    setSelectedItems={setAdvertisers}
                    disabled={!isAuthorized}
                  />
                )}
              </div>

              {/* Report Type */}
              <div className="mb-6">
                <h3 className="text-sm font-semibold mb-4">Report Type</h3>
                <SingleSelectDropdown
                  items={["Standard", "Youtube", "Floodlight"]}
                  selectedItem={reportType}
                  setSelectedItem={setReportType}
                  disabled={!isAuthorized}
                />
              </div>

              {/* Dimensions */}
              <div className="mb-6">
                <h3 className="text-sm font-semibold mb-4">Dimensions</h3>
                <MultiSelectDropdown
                  items={getDimensions()}
                  selectedItems={dimensions}
                  setSelectedItems={setDimensions}
                  disabled={!isAuthorized || !reportType}
                />
              </div>

              {/* Metrics */}
              <div className="mb-6">
                <h3 className="text-sm font-semibold mb-4">Metrics</h3>
                <MultiSelectDropdown
                  items={getMetrics()}
                  selectedItems={metrics}
                  setSelectedItems={setMetrics}
                  disabled={!isAuthorized || !reportType}
                />
              </div>

              {/* Historical Sync Time Frame */}
              <div className="mb-6">
                <h3 className="text-sm font-semibold mb-4">
                  Historical sync time frame
                </h3>
                <SingleSelectDropdown
                  items={["30 Days", "60 Days", "90 Days"]}
                  selectedItem={historicalSyncTimeFrame}
                  setSelectedItem={setHistoricalSyncTimeFrame}
                  disabled={!isAuthorized}
                />
              </div>
            </div>
          ) : (
            <div>
              {/* Configuration Name */}
              <div className="mb-6">
                <h3 className="text-sm font-semibold mb-4">Report ID</h3>
                <input
                  type="text"
                  className="border outline-none p-3 w-96 h-[42px] text-sm"
                  placeholder="Report Id..."
                  value={queryId}
                  onChange={(e) => setQueryId(e.target.value)}
                  disabled={!isAuthorized}
                />
              </div>
            </div>
          )}

          {/* Scheduler Setting */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Schedule Setting</h3>
            <div className="flex gap-4 items-center">
              <button
                className={`text-sm border border-gray-300 p-2 ${
                  !isAuthorized ? "bg-gray-200" : "bg-white"
                }`}
                onClick={() => setOpenSchedule((prev) => !prev)}
                disabled={!isAuthorized}
              >
                +
              </button>
              <p>{schedulePreview}</p>
            </div>
          </div>
        </div>
      </div>

      {/* scheduler popup */}
      {openSchedule && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div
            className="bg-white shadow-lg min-w-[413px] border border-gray-300"
            style={{
              boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
            }}
          >
            <div className="pt-6 px-8 pb-6">
              <div className="flex justify-end">
                <button
                  onClick={() => setOpenSchedule((prevValue) => !prevValue)}
                  className=""
                >
                  <IoClose />
                </button>
              </div>
              <ScheduleSettings
                setSchedulingUserInput={setSchedulingUserInput}
                setSchedulePreview={setSchedulePreview}
              />
            </div>
          </div>
        </div>
      )}
      <div className="fixed bottom-0 left-[100px] right-0 bg-gray-100 border-t border-gray-300 py-8 flex justify-between items-center px-6 z-20 overflow-hidden">
        <Link
          to="/connectors-library"
          className=" flex items-center gap-2"
          onClick={() => setIsSaveAndTestActive(false)}
        >
          <IoChevronBack size={16} />
          <span className="text-base">Back</span>
        </Link>
        <div className="flex gap-2">
          <button
            className={`border-[0.75px] ${
              isSaveAndTestActive
                ? "border-[#286043] bg-[#286043]"
                : "bg-gray-300 border-gray-300"
            } text-white text-sm font-semibold py-[6px] px-3`}
            disabled={!isSaveAndTestActive}
            onClick={handleSaveAndTest}
          >
            {saveAndTestLoader ? (
              <div className="flex gap-2 items-center">
                <Loader loaderColor="text-white" />
                Saving & Testing...
              </div>
            ) : (
              "Save & Test"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInputForDV360;
