import React, { useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { PiCalendarBlank } from "react-icons/pi";
import { Checks } from "../interfaces/brandSafety";
import { GoLinkExternal } from "react-icons/go";

interface ActionItemComponentProps {
  item: Checks;
  setQueuedActionsData?: React.Dispatch<React.SetStateAction<Checks[]>>;
  dataFor?: string;
  setShowDeletePopup?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedForDelete?: React.Dispatch<React.SetStateAction<Checks>>;
  onAddToQueue?: (item: any) => void;
  onMarkAsIrrelevant?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFromQueue?: Checks[];
  setSelectedFromQueue?: React.Dispatch<React.SetStateAction<Checks[]>>;
}

const ActionItemComponent: React.FC<ActionItemComponentProps> = ({
  item,
  setQueuedActionsData,
  dataFor,
  setShowDeletePopup,
  setSelectedForDelete,
  onAddToQueue,
  onMarkAsIrrelevant,
  selectedFromQueue,
  setSelectedFromQueue,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleAddToQueue = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onAddToQueue) {
      onAddToQueue(item);
    }
    setIsOpen(false);
  };

  const handleDeleteButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (setShowDeletePopup && setSelectedForDelete) {
      setShowDeletePopup(true);
      setSelectedForDelete(item);
    }
    setIsOpen(false);
  };

  const handleMarkAsIrrelevant = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onMarkAsIrrelevant && setSelectedForDelete) {
      onMarkAsIrrelevant(true);
      setSelectedForDelete(item);
    }
    setIsOpen(false);
  };

  const handleCheckUncheckForQueuedActions = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: Checks
  ) => {
    if (e.target.checked && setSelectedFromQueue) {
      setSelectedFromQueue((prev) => [...prev, item]);
    } else if (selectedFromQueue) {
      const result = selectedFromQueue.filter(
        (element) => element.id !== item.id
      );
      setSelectedFromQueue?.(result);
    }
  };

  function formatDate(dateString: string | null) {
    if (dateString) {
      const date = new Date(dateString);
      if (!isNaN(date.getTime())) {
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const year = date.getFullYear().toString().slice(-2);
        return `${day}/${month}/${year}`;
      }
    }
    return null;
  }

  const renderCriticality = (criticality: number) => {
    switch (Number(criticality)) {
      case 1:
        return (
          <span className="border-[0.5px] rounded-xl text-xs font-medium py-1 px-2 border-[#F01F1F] bg-[#f4e1e1] text-[#F01F1F]">
            Threat
          </span>
        );
      case 4:
        return (
          <span className="border-[0.5px] rounded-xl text-xs font-medium py-1 px-2 border-[#FF9E44] bg-[#f4e7dc] text-[#FF9E44]">
            Anomaly
          </span>
        );
      case 2:
        return (
          <span className="border-[0.5px] rounded-xl text-xs font-medium py-1 px-2 border-[#C4AB2D] bg-[#f6f1db] text-[#C4AB2D]">
            Warning
          </span>
        );
      case 3:
        return (
          <span className="border-[0.5px] rounded-xl text-xs font-medium py-1 px-2 border-[#28BE5B] bg-[#d8f1e0] text-[#28BE5B]">
            Optimization
          </span>
        );
      default:
        return (
          <span className="border-[0.5px] rounded-xl text-xs font-medium py-1 px-2 bg-white text-black">
            None
          </span>
        );
    }
  };

  const getDataSection = () => {
    return [
      {
        label: "Advertisers",
        name:
          item?.campaign_checks?.data?.[0]?.advertiser?.advertiser_name || null,
        id: item?.campaign_checks?.data?.[0]?.advertiser?.advertiser_id || null,
        url:
          item?.campaign_checks?.partner_id &&
          item?.campaign_checks?.data?.[0]?.advertiser?.advertiser_id
            ? `https://displayvideo.google.com/ng_nav/p/${item.campaign_checks.partner_id}/a/${item.campaign_checks.data[0].advertiser.advertiser_id}`
            : null,
      },
      {
        label: "Campaign",
        name: item?.campaign_checks?.data?.[0]?.campaign?.campaign_name || null,
        id: item?.campaign_checks?.data?.[0]?.campaign?.campaign_id || null,
        url:
          item?.campaign_checks?.partner_id &&
          item?.campaign_checks?.data?.[0]?.advertiser?.advertiser_id &&
          item?.campaign_checks?.data?.[0]?.campaign?.campaign_id
            ? `https://displayvideo.google.com/ng_nav/p/${item.campaign_checks.partner_id}/a/${item.campaign_checks.data[0].advertiser.advertiser_id}/c/${item.campaign_checks.data[0].campaign.campaign_id}`
            : null,
      },
      item?.campaign_checks?.data?.[0]?.insertion_order && {
        label: "Insertion order",
        name:
          item?.campaign_checks?.data?.[0]?.insertion_order
            ?.insertion_order_name || null,
        id:
          item?.campaign_checks?.data?.[0]?.insertion_order
            ?.insertion_order_id || null,
        url:
          item?.campaign_checks?.partner_id &&
          item?.campaign_checks?.data?.[0]?.advertiser?.advertiser_id &&
          item?.campaign_checks?.data?.[0]?.campaign?.campaign_id &&
          item?.campaign_checks?.data?.[0]?.insertion_order?.insertion_order_id
            ? `https://displayvideo.google.com/ng_nav/p/${item.campaign_checks.partner_id}/a/${item.campaign_checks.data[0].advertiser.advertiser_id}/c/${item.campaign_checks.data[0].campaign.campaign_id}/io/${item.campaign_checks.data[0].insertion_order.insertion_order_id}`
            : null,
      },
      item?.campaign_checks?.data?.[0]?.line_item && {
        label: "Line item",
        name:
          item?.campaign_checks?.data?.[0]?.line_item?.line_item_name || null,
        id: item?.campaign_checks?.data?.[0]?.line_item?.line_item_id || null,
        url:
          item?.campaign_checks?.partner_id &&
          item?.campaign_checks?.data?.[0]?.advertiser?.advertiser_id &&
          item?.campaign_checks?.data?.[0]?.campaign?.campaign_id &&
          item?.campaign_checks?.data?.[0]?.insertion_order
            ?.insertion_order_id &&
          item?.campaign_checks?.data?.[0]?.line_item?.line_item_id
            ? `https://displayvideo.google.com/ng_nav/p/${item.campaign_checks.partner_id}/a/${item.campaign_checks.data[0].advertiser.advertiser_id}/c/${item.campaign_checks.data[0].campaign.campaign_id}/io/${item.campaign_checks.data[0].insertion_order?.insertion_order_id}/li/${item.campaign_checks.data[0].line_item.line_item_id}`
            : null,
      },
      item?.campaign_checks?.data?.[0]?.ad_group && {
        label: "Ad Group",
        name: item?.campaign_checks?.data?.[0]?.ad_group?.ad_group_name || null,
        id: item?.campaign_checks?.data?.[0]?.ad_group?.ad_group_id || null,
        url:
          item?.campaign_checks?.partner_id &&
          item?.campaign_checks?.data?.[0]?.advertiser?.advertiser_id &&
          item?.campaign_checks?.data?.[0]?.campaign?.campaign_id &&
          item?.campaign_checks?.data?.[0]?.insertion_order
            ?.insertion_order_id &&
          item?.campaign_checks?.data?.[0]?.line_item?.line_item_id &&
          item?.campaign_checks?.data?.[0]?.ad_group?.ad_group_id
            ? `https://displayvideo.google.com/ng_nav/p/${item.campaign_checks.partner_id}/a/${item.campaign_checks.data[0].advertiser.advertiser_id}/c/${item.campaign_checks.data[0].campaign.campaign_id}/io/${item.campaign_checks.data[0].insertion_order?.insertion_order_id}/li/${item.campaign_checks.data[0].line_item?.line_item_id}/adgroups`
            : null,
      },
    ].filter(Boolean);
  };

  return (
    <div className="border-b border-gray-200 px-3">
      <div
        className="flex items-center justify-between cursor-pointer py-4"
        onClick={toggleAccordion}
      >
        <div className="w-2/6">
          <span className="text-gray-900 text-xs leading-4 font-[400] flex items-center gap-2">
            {dataFor === "Queued Actions" && (
              <input
                type="checkbox"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  handleCheckUncheckForQueuedActions(e, item);
                }}
                className="form-checkbox h-4 w-4 text-gray-600 border-gray-500 checked:bg-gray-900 hover:bg-gray-200 outline-none"
                checked={
                  !!selectedFromQueue?.find((element) => element.id === item.id)
                }
              />
            )}
            <span>
              {isOpen ? (
                <IoIosArrowUp size={15} />
              ) : (
                <IoIosArrowDown size={15} />
              )}
            </span>
            {item?.campaign_checks?.data?.length > 0
              ? item.campaign_checks.data[0]?.title
              : "Unknown"}
          </span>
        </div>
        <div className="flex items-center w-4/6 justify-between ml-5">
          <span className="text-gray-900 font-normal text-xs leading-4 w-48 overflow-hidden flex justify-start items-center">
            <span className="text-center bg-gray-100 mr-1 p-3 rounded-full flex items-center justify-center h-6 w-6">
              {item?.campaign_checks?.data?.length > 0
                ? item?.campaign_checks?.data?.[0]?.campaign?.campaign_name?.[0]?.toUpperCase()
                : null}
            </span>
            <span>
              {item?.campaign_checks?.data?.length > 0
                ? item?.campaign_checks?.data?.[0]?.campaign?.campaign_name
                : "Unknown"}
            </span>
          </span>
          <span className="text-gray-900 font-normal text-xs leading-4 w-28 overflow-hidden flex items-center gap-1">
            <span>
              <PiCalendarBlank size={16} />
            </span>
            <span>{formatDate(item.check_last_updated_at)}</span>
          </span>
          <span className="w-20">
            {item?.campaign_checks?.data?.length > 0 &&
              renderCriticality(item.campaign_checks.data[0].threat)}
          </span>
          {dataFor !== "Queued Actions" && (
            <div className="flex items-center space-x-2">
              <button
                className="text-xs text-green-700 underline"
                onClick={handleAddToQueue}
              >
                {dataFor === "Brand Safety" ? "Add to queue" : "Restore"}
              </button>
              <button
                className="text-xs text-red-500"
                onClick={handleDeleteButton}
              >
                <RiDeleteBinLine size={18} />
              </button>
            </div>
          )}
        </div>
      </div>

      {isOpen && (
        <div className="py-2 px-4">
          <div className="text-xs text-gray-600">
            <p className="mb-5">
              <b>Check Type : </b>
              {item?.check_type || "Unknown"}
            </p>
            <div className="flex justify-start gap-5">
              {getDataSection().map((section, index) => (
                <div className="flex flex-col justify-start pb-2" key={index}>
                  <span className="font-bold">{section?.label}</span>
                  <span>
                    {section?.name}
                    <span className="flex items-center gap-1">
                      ({section?.id}){" "}
                      {section?.url && (
                        <a
                          href={section.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <GoLinkExternal size={14} color="#0560ff" />
                        </a>
                      )}
                    </span>
                  </span>
                </div>
              ))}
            </div>
            <div className="pt-4">
              <span className="font-bold">Recommended action:</span>
              <p className="text-gray-500">
                {item?.campaign_checks?.data?.[0]?.recommended_action ||
                  "No action"}
              </p>
              {!(dataFor === "Archive") && (
                <button
                  className="text-sm text-[#286043] mt-4 border-[0.75px] border-[#286043] px-3 py-2"
                  onClick={handleMarkAsIrrelevant}
                >
                  Mark as irrelevant
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionItemComponent;
