import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import UserAvatar from "../components/userAvatar/UserAvatar";
import { IoCreateOutline } from "react-icons/io5";
import SingleSelectDropdown from "../components/SingleSelectDropdown";
import toast from "react-hot-toast";
import Loader from "../components/statusIndicator/loader";

const sampleListForIfCondition = [
  "Campaign status",
  "Brand safety settings",
  "Sensitivity settings",
  "End date",
  "Frequency cap",
  "Geography",
  "Language",
  "Performance goal",
  "Start date",
  "Budget",
  "Pacing period",
  "Pacing type",
  "Bid strategy",
  "Partner revenue model",
];

const operatorsList = ["=", "<", ">", "<=", ">=", "!="];

const CreateRule: React.FC = () => {
  const [ruleTitle, setRuleTitle] = useState<string>("");
  const [dsp, setDsp] = useState<string | null>(null);
  const [level, setLevel] = useState<string | null>(null);
  const [selectedCriticality, setSelectedCriticality] = useState("Threat");
  const [ifCondition, setIfCondition] = useState<string | null>(null);
  const [andCondition, setAndCondition] = useState<string | null>(null);
  const [operator, setOperator] = useState<string>("=");
  const [andOperatorSelect, setAndOperatorSelect] = useState<string>("=");
  const [value, setValue] = useState<string>("");
  const [andUserInput, setAndUserInput] = useState<string>("");
  const [logicalOperator, setLogicalOperator] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isAddAnotherCondition, setIsAddAnotherCondition] =
    useState<boolean>(true);
  const [loaderOnCreateRuleBtnClick, setLoaderOnCreateRuleBtnClick] =
    useState<boolean>(false);
  const [rulesCount, setRulesCount] = useState<number>(0);

  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    }
  }, [accessToken, refreshToken, navigate]);

  const resetStates = () => {
    setRuleTitle("");
    setDsp(null);
    setLevel(null);
    setSelectedCriticality("Threat");
    setIfCondition(null);
    setAndCondition(null);
    setOperator("");
    setAndOperatorSelect("");
    setValue("");
    setAndUserInput("");
    setLogicalOperator(null);
    setErrorMessage("");
    setLoaderOnCreateRuleBtnClick(false);
  };

  const handleCreateRuleClick = () => {
    if (!ruleTitle || !dsp || !selectedCriticality || !ifCondition || !value) {
      toast.error("Fill all the fields");
      return;
    }
    setLoaderOnCreateRuleBtnClick(true);
    setTimeout(() => {
      setLoaderOnCreateRuleBtnClick(false);
      toast.success("Rule Created");
      resetStates();
    }, 2000);
  };

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="create rule" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        {/* Heading Section */}
        <div className="flex justify-start items-center gap-4 mt-4 border-b-[0.75px] pb-[21px]">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <IoCreateOutline size={20} />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">
              Rule Creation
            </p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              Create your custom rules
            </p>
          </div>
        </div>

        {/* form component */}
        <div className="mt-6 mb-32">
          <div className="flex flex-col gap-4 mb-5">
            <label htmlFor="rule_title" className="font-bold text-sm">
              Rule Description
            </label>
            <input
              type="text"
              placeholder="Rule Description"
              className="w-[410px] h-10 border-[0.5px] border-gray-300 p-3 text-sm"
              value={ruleTitle}
              onChange={(e) => setRuleTitle(e.target.value)}
            />
          </div>
          <div className="mb-5 w-[410px]">
            <label htmlFor="dsp" className="font-bold text-sm">
              DSP
            </label>
            <SingleSelectDropdown
              items={["DV360"]}
              selectedItem={dsp}
              setSelectedItem={setDsp}
            />
          </div>
          <div className="mb-5 w-[410px]">
            <label htmlFor="dsp" className="font-bold text-sm">
              Level
            </label>
            <SingleSelectDropdown
              items={["Insertion Order", "Line item", "Creative", "Ad group"]}
              selectedItem={level}
              setSelectedItem={setLevel}
            />
          </div>
          <p className="font-bold text-sm">CONDITION</p>
          <div>
            <div className="flex items-center gap-12">
              <label htmlFor="if" className="w-24 text-sm">
                If
              </label>
              <div className="w-[410px]">
                <SingleSelectDropdown
                  items={sampleListForIfCondition}
                  selectedItem={ifCondition}
                  setSelectedItem={setIfCondition}
                />
              </div>
            </div>
            <div className="flex items-center gap-12">
              <label htmlFor="is" className="w-24 text-sm">
                is
              </label>
              <div className="flex gap-2">
                <select
                  value={operator}
                  onChange={(event) => setOperator(event?.target.value)}
                  className="w-[190px] h-10 border-[0.5px] border-gray-300 px-3 mt-3 text-bold text-lg outline-none"
                >
                  {operatorsList.map((operatorOption, index) => (
                    <option key={index} value={operatorOption}>
                      {operatorOption}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  placeholder="Value"
                  className="w-[210px] h-10 border-[0.5px] border-gray-300 p-3 text-sm mt-3 outline-none"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
            </div>
          </div>
          {!isAddAnotherCondition && (
            // add another rule section
            <div>
              <div className="flex items-center gap-12">
                <div className="w-24">
                  <SingleSelectDropdown
                    items={["AND", "OR", "NOT"]}
                    selectedItem={logicalOperator}
                    setSelectedItem={setLogicalOperator}
                  />
                </div>
                <div className="w-[410px]">
                  <SingleSelectDropdown
                    items={sampleListForIfCondition}
                    selectedItem={andCondition}
                    setSelectedItem={setAndCondition}
                  />
                </div>
              </div>
              <div className="flex items-center gap-12">
                <label htmlFor="is" className="w-24 text-sm">
                  is
                </label>
                <div>
                  <div className="flex gap-2">
                    <select
                      value={andOperatorSelect}
                      onChange={(event) =>
                        setAndOperatorSelect(event?.target.value)
                      }
                      className="w-[190px] h-10 border-[0.5px] border-gray-300 px-3 mt-3 text-bold text-lg outline-none"
                    >
                      {operatorsList.map((operatorOption, index) => (
                        <option key={index} value={operatorOption}>
                          {operatorOption}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      placeholder="Value"
                      className="w-[210px] h-10 border-[0.5px] border-gray-300 p-3 text-sm mt-3 outline-none"
                      value={andUserInput}
                      onChange={(e) => setAndUserInput(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Add new rule section */}
          {isAddAnotherCondition ? (
            <button
              className="text-sm text-[#286043] my-3"
              onClick={() => setIsAddAnotherCondition(false)}
            >
              + Add Another Rule
            </button>
          ) : (
            <button
              className="text-sm text-red-500 my-3"
              onClick={() => {
                setIsAddAnotherCondition(true);
                setLogicalOperator(null);
                setAndCondition(null);
                setAndOperatorSelect("=");
                setAndUserInput("");
              }}
            >
              - Remove the added rule
            </button>
          )}

          <div className="flex gap-12 mt-4">
            <label htmlFor="if" className="w-24 text-sm mt-2">
              then show error message
            </label>
            <div className="w-[410px]">
              <textarea
                className="w-[410px] h-[120px] border-[0.5px] border-gray-300 p-3 text-sm mt-3"
                value={errorMessage}
                onChange={(e) => setErrorMessage(e.target.value)}
              />
            </div>
          </div>

          {/* criticality */}
          <p className="font-bold text-sm">Criticality</p>
          <div className="flex gap-2 mt-2">
            {["Threat", "Warning", "Anomaly"].map((item) => (
              <button
                key={item}
                className={`border p-2 text-xs ${
                  selectedCriticality === item ? "bg-gray-200" : ""
                }`}
                onClick={() => setSelectedCriticality(item)}
              >
                {item}
              </button>
            ))}
          </div>
        </div>
        <div className="fixed bottom-0 left-[100px] right-0 bg-gray-100 border-t border-gray-300 py-8 flex justify-between items-center px-6 z-20 overflow-hidden">
          <button
            className="border bg-[#286043] border-[#286043] p-3 flex justify-center items-center gap-1"
            onClick={handleCreateRuleClick}
          >
            {loaderOnCreateRuleBtnClick ? (
              <Loader loaderColor="text-white" />
            ) : (
              <span className="text-sm leading-4 text-left font-[600]  text-white">
                Create Rule
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateRule;
