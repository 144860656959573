import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import UserAvatar from "../components/userAvatar/UserAvatar";
import NoActionsFound from "../components/noActionsFound";
import { MdOutlineCreate } from "react-icons/md";

const CreateTaxonomy: React.FC = () => {
  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    }
  }, [accessToken, refreshToken, navigate]);

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="create taxonomy" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        {/* Heading Section */}
        <div className="flex justify-start items-center gap-4 mt-4 border-b-[0.75px] pb-[21px]">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <MdOutlineCreate size={20} />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">
              Create Taxonomy
            </p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              Create your Rules Set
            </p>
          </div>
        </div>
        {/* component */}
        <NoActionsFound sectionName="Create Taxonomy" />
      </div>
    </div>
  );
};

export default CreateTaxonomy;
